import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouteDataProvider } from './providers/RouteDataProvider.provider';
import { HttpClientModule } from '@angular/common/http';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuardService } from '../services/auth/auth-guard.service';
import { AuthenticationCheckService } from '../services/auth/authentication-check.service';
// import { ToastService } from '../services/toast/toast.service';

import { XlsxViewComponent } from './xlsx-view/xlsx-view.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { ColorSketchModule } from 'ngx-color/sketch';
import { ColorBlockModule } from 'ngx-color/block';
import { ColorPaletteComponent } from './color-palette/color-palette.component';
import { ImageViewerForEditorComponent } from './image-viewer-for-editor/image-viewer-for-editor.component';
import { RangeDatePickerComponent } from './range-date-picker/range-date-picker.component';
import { AccordionConfig, AccordionModule } from "ngx-bootstrap/accordion";

//import { ChartsModule } from 'ng2-charts';
import { ChartsModule } from 'ng2-charts/ng2-charts';

@NgModule({
  declarations: [
    XlsxViewComponent,
    ColorPaletteComponent,
    ImageViewerForEditorComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    HttpClientModule,
    NgxUiLoaderModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule,
    FormsModule,
    CKEditorModule,
    ColorSketchModule,
    ColorBlockModule,
    AccordionModule.forRoot(),
    ChartsModule

  ],
  exports:[
    NgbModule,
    HttpClientModule,
    NgxUiLoaderModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule,
    FormsModule,
    CKEditorModule,
    ColorSketchModule,
    ColorBlockModule,
    AccordionModule,
    ChartsModule
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        RouteDataProvider,
        AuthGuardService,
        AuthenticationCheckService,
        // ToastService,
        DatePipe,
        { provide: AccordionConfig, useValue: { closeOthers: true } },
      ]
    };
  }
}
