/* tslint:disable */
import { Injectable } from "@angular/core";

/**
 * Global configuration for Api services
 */
@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {
  // baseUrl ='http://staging15api.bigcityexperiences.co.in';
  //STAGING
  baseUrl2 = 'https://api3.staging6.bigcityvoucher.co.in/v1';
  //STAGING 2 vikash-staging
  //baseUrl2 = 'http://hubadminapi.bigcityvoucher.co.in/v1'
  // baseUrl2 = 'http://hubadminapi.bigcityvoucher.co.in/v1'
  //test
  // baseUrl2 = 'http://api1.staging10.bigcityvoucher.co.in/v1/adminreport/earning-report?program_id=1';
  //VIJAY BASKARS
  // baseUrl2 = 'http://192.168.1.163/vadmin/hubadminapi/web/v1';
  //SURESH
  // baseUrl2 = "http://localhost/Vanila-Hub-admin/hubadminapi/web/v1";
  //Anila
  // baseUrl2 = "http://192.168.1.158/hubadminapi/web/v1";
  // General
  login = this.baseUrl2 + "/login/login";

  getDashBoardDetails =
    this.baseUrl2 + "/adminreport/generalreports?program_id=1&";
  //***MASTERDATA***/
  // Region
  configGetRegion = this.baseUrl2 + "/region/get-region?program_id=1";
  configAddRegion = this.baseUrl2 + "/region/create-region";
  configUpdateRegion = this.baseUrl2 + "/region/update-region";
  configDeleteRegion = this.baseUrl2 + "/region/delete-region";
  importExcelRegion = this.baseUrl2 + "/region/import-region";
  //states
  configGetStates = this.baseUrl2 + "/state/get-state?program_id=1";
  configAddState = this.baseUrl2 + "/state/create-state";
  configEditState = this.baseUrl2 + "/state/update-state";
  configDeleteState = this.baseUrl2 + "/state/delete-state";
  importExcelState = this.baseUrl2 + "/state/import-state";
  //Cities
  configGetCities = this.baseUrl2 + "/city/get-city?program_id=1";
  configAddCity = this.baseUrl2 + "/city/create-city";
  configEditCity = this.baseUrl2 + "/city/update-city";
  configDeleteCity = this.baseUrl2 + "/city/delete-city";
  importExcelCity = this.baseUrl2 + "/city/import-city";
  //Brands
  configGetBrands = this.baseUrl2 + "/brands/get-brand?program_id=1";
  configAddBrand = this.baseUrl2 + "/brands/create-brand";
  configEditBrand = this.baseUrl2 + "/brands/update-brand";
  configDeleteBrand = this.baseUrl2 + "/brands/delete-brand";
  //Channels
  configGetChannel = this.baseUrl2 + "/channels/get-channel?program_id=1";
  configAddChannel = this.baseUrl2 + "/channels/create-channel";
  configEditChannel = this.baseUrl2 + "/channels/update-channel";
  configDeleteChannel = this.baseUrl2 + "/channels/delete-channel";
  // Languages
  configGetLanguages = this.baseUrl2 + "/language/get-languages";
  configAddLanguage = this.baseUrl2 + "/language/create-language";
  configEditLanguage = this.baseUrl2 + "/language/update-language";
  configDeleteLanguage = this.baseUrl2 + "/channels/delete-channel";
  // Frequency
  configGetFrequency = this.baseUrl2 + "/frequency/get-frequency";
  configAddFrequency = this.baseUrl2 + "/frequency/create-frequency";
  configEditFrequency = this.baseUrl2 + "/frequency/update-frequency";
  // Units
  configGetUnit = this.baseUrl2 + "/units/get-units";
  configAddUnit = this.baseUrl2 + "/units/create-unit";
  configEditUnit = this.baseUrl2 + "/units/update-unit?id=";
  // Brand Categories
  configGetBrandCategory =
    this.baseUrl2 + "/brand-category/get-brand-categories";
  configAddBrandCategory =
    this.baseUrl2 + "/brand-category/create-brand-category";
  configUpdateBrandCategory =
    this.baseUrl2 + "/brand-category/update-brand-category"; //same for delete
  //ROLES
  configGetRoles = this.baseUrl2 + "/user-role/get-user-roles";
  configAddRoles = this.baseUrl2 + "/user-role/create-user-role";
  configUpdateRoles = this.baseUrl2 + "/user-role/update-user-role"; //same for delete
  //***USER MANAGEMENT***/
  allUsers = this.baseUrl2 + "/users/get-users";
  allProfiles = this.baseUrl2 + "/user-role/get-user-roles";
  createUserPost = this.baseUrl2 + "/users/create-user";
  updateUserPost = this.baseUrl2 + "/users/update-user?id=";
  importExcelUser = this.baseUrl2 + "/users/import-users";
  userRegistrationDetails = this.baseUrl2 + '/user-role/supervisors?'
  getUserById = this.baseUrl2 + "/users/get-users?id=";
  //Filter data, regions,channel,roles, geography
  filterDataParams = this.baseUrl2 + "/course/get-course?program_id=1";
  //GET OUTLETS MAPPED TO A TSE
  getMappedOutlets = this.baseUrl2 + "/visit-tracker/get-tse-outlets";
  //visit tracker
  getCampaingsBasedOnFilter = this.baseUrl2 + '/campaign/search-campaigns';
  getTasksBasedOnCampaign = this.baseUrl2 + '/visit-tracker/visit-tracker-tasks?program_id=1&campaign_id=';
  getVtTseList = this.baseUrl2 + '/visit-tracker/visit-tracker-tse?program_id=1&task_id=';
  //**Channel**//
  getAllChannel = this.baseUrl2 + "/channels/get-channel?program_id=1";

  //***REWARDS***//
  //reward category
  getRewardCategories =
    this.baseUrl2 + "/rewards/get-reward-category?program_id=1";
  addRewardCategories = this.baseUrl2 + "/rewards/create-reward-category";
  editRewardCategories = this.baseUrl2 + "/rewards/update-reward-category";
  deleteRewardCategories = this.baseUrl2 + "/rewards/delete-reward-category";
  rewardHistory = this.baseUrl2 + "/rewards/get-reward-orders?program_id=1";
  getRewardProducts =
    this.baseUrl2 + "/rewards/get-reward-products?program_id=1";
  getRewardProductbyId =
    this.baseUrl2 + "/rewards/get-reward-products?program_id=1&id=";
  createRewardProducts = this.baseUrl2 + "/rewards/create-reward-products";
  updateRewardProducts = this.baseUrl2 + "/rewards/update-reward-products";
  getRewardSubCategory =
    this.baseUrl2 +
    "/rewards/get-reward-sub-categories?program_id=1&reward_category_id=";
  getRewardsBrands =
    this.baseUrl2 +
    "/rewards/get-reward-category-brands?program_id=1&reward_category_id=";

  //reward sub category
  getRewardSubCategories =
    this.baseUrl2 + "/rewards/get-reward-subcategory?program_id=1";
  addRewardSubCategories = this.baseUrl2 + "/rewards/create-reward-subcategory";
  editRewardSubCategories =
    this.baseUrl2 + "/rewards/update-reward-subcategory";
  deleteRewardSubCategories =
    this.baseUrl2 + "/rewards/delete-reward-subcategory";
  //Reward Type
  getRewardType = this.baseUrl2 + "/reward-type/get-reward-types";
  addRewardType = this.baseUrl2 + "/reward-type/create-reward-type?id=1";
  editRewardType = this.baseUrl2 + "/reward-type/update-reward-type?id=";
  deleteRewardType = this.baseUrl2 + "/reward-type/update-reward-type?id=";
  // Engagement API
  engagementCreateActivity =
    this.baseUrl2 + "/engagement/create-campaign-activity";
  engagementUpdate = this.baseUrl2 + "/engagement/update-task?id=";
  getAllEngagement = this.baseUrl2 + "/engagement/get-engagements";
  createEngagementTask = this.baseUrl2 + "/engagement/create-task";
  getUserAssignment = this.baseUrl2 + "/user-modules/get-all-whitelisted-user";
  getUsersFilter = this.baseUrl2 + "/users/search-users-by-filter";
  getQuizDetails = this.baseUrl2 + "/engagement/get-quiz-activities?";
  assignUserDetails = this.baseUrl2 + "/engagement/assign-user-task";
  createQuizEngage = this.baseUrl2 + "/engagement/create-quiz";
  updateQuizEngage = this.baseUrl2 + "/engagement/update-quiz?id=";
  createpointsConfig =
    this.baseUrl2 + "/engagement/create-engagement-point-structure";
  getEngagementTaskPoints =
    this.baseUrl2 + "/engagement/engagement-point-structure?task_id=";
  getAssignedUsers = this.baseUrl2 + "/engagement/get-user-tasks?task_id=";
  uploadImages = this.baseUrl2 + "/engagement/upload-img";
  /**
   * Activity
   */
  campaign_activites = this.baseUrl2 + "/engagement/get-campaign-activites";
  createActivity = this.baseUrl2 + "/engagement/create-campaign-activity";
  updateActivity = this.baseUrl2 + "/engagement/update-campaign-activity?id=";
  getActivityBasedOnTask =
    this.baseUrl2 + "/engagement/get-campaign-activites?campaign_id=";
  //orders
  getAllOrders = this.baseUrl2 + "/ecommerce/get-ecommerce-orders";
  getOrderDetails = this.baseUrl2 + "/ecommerce/view-ecommerce-order?id=";
  orderApproveReject = this.baseUrl2 + "/ecommerce/accept-reject-order";
  getAllCategiry =
    this.baseUrl2 + "/ecommerce/get-ecommerce-categories?program_id=1";
  createCatgory = this.baseUrl2 + "/ecommerce/create-ecommerce-categories";
  updateCategory = this.baseUrl2 + "/ecommerce/update-ecommerce-categories";

  //***REWARDS***//
  getAllOffers =
    this.baseUrl2 + "/ecommerce/loaddata-for-offer-details?program_id=1";
  //Schemes
  getSchemes = this.baseUrl2 + "/scheme/get-scheme";
  addSchemes = this.baseUrl2 + "/scheme/create-scheme-data";
  getSchemeConfig = this.baseUrl2 + "/scheme/get-scheme?id=";
  getSchemeProductCategory = this.baseUrl2 + "/scheme/get-brand-category";
  extendScheme = this.baseUrl2 + '/scheme/update-scheme-date?id=';
  getSchemeConfigSettings = this.baseUrl2 + '';
  updateScheme = this.baseUrl2 + '/scheme/update-scheme?id='
  //Visibility /get-posm-config
  getVisibilityList =
    this.baseUrl2 + "/visibility/get-posm-elements?program_id=1";
  addVisibility = this.baseUrl2 + "/visibility/create-posm-elements";
  editVisibility = this.baseUrl2 + "/visibility/update-posm-elements";
  deleteVisibility = this.baseUrl2 + "/visibility/delete-posm-elements";
  saveVisibilityConfig = this.baseUrl2 + "/visibility/create-posm-config";
  getVisibilityConfig =
    this.baseUrl2 + "/visibility/get-posm-config?program_id=1";
  updateVisibilityConfig = this.baseUrl2 + "/visibility/update-posm-config";
  getVisibilitySubmittedList =
    this.baseUrl2 +
    "/visibility/get-posm-audit-transactions?page=";
  visibilityApproveOrReject =
    this.baseUrl2 + "/visibility/posm-audit-approve-reject";
  getPosmOnChannel = this.baseUrl2 + '/visibility/get-channel-posm-elements?program_id=1&channel_id=';
  getShelfConditions = this.baseUrl2 + '/visibility/get-shelves-cooler-purity?program_id=1'
  getShelfConditionsForSubmission = this.baseUrl2 +'/visibility/get-shelves-points?program_id=1&posm_master_config=';
  //campaign
  getAllCampaign = this.baseUrl2 + "/campaign/get-campaigns";
  addCampaign = this.baseUrl2 + "/campaign/create-campaign";
  editCampaign = this.baseUrl2 + "/campaign/update-campaign";
  //* GEOGRAPHY *//
  getAllGeographies = this.baseUrl2 + "/geographical/get-geographs";
  addGeography = this.baseUrl2 + "/geographical/create-geography";
  editGeography = this.baseUrl2 + "/geographical/update-geography?id=";
  //ecommerce
  getProductsList = this.baseUrl2 + "/product/listallproducts?program_id=1";
  getProductById =
    this.baseUrl2 + "/product/editproduct?program_id=1&product_id=";
  getProductDelete = this.baseUrl2 + "/product/deleteproduct";
  addToProductList = this.baseUrl2 + "/product/addnewproduts";
  getProductCategories =
    this.baseUrl2 + "/ecommerce/get-ecommerce-categories?program_id=1";
  updateProduct = this.baseUrl2 + "/product/updateproduct";
  createGeography = this.baseUrl2 + "/geographical/create-geography";
  getProductVarient = this.baseUrl2 + "/product/get-product-variants";
  getProductVarientValues =
    this.baseUrl2 + "/product/get-product-variant-values?product_variant_id=";
  createOfferDetails = this.baseUrl2 + "/ecommerce/create-offer-details";
  getOfferDetails =
    this.baseUrl2 + "/ecommerce/get-offer-details?program_id=1&offer_id=";
  getEcommerceSubCategories =
    this.baseUrl2 +
    "/ecommerce/get-ecommerce-sub-categories?program_id=1&category_id=";
  //****carton Insert****//
  getCartonList = this.baseUrl2 + "/tertiary-programs/get-cartons?program_id=1";
  getCartonCodes =
    this.baseUrl2 +
    "/tertiary-programs/get-cartons-codes?program_id=1&carton_id=";
  createCarton = this.baseUrl2 + "/tertiary-programs/create-cartons";
  generateQRImages =
    this.baseUrl2 + "/tertiary-programs/qr-code-generation-api";
  /**
   * Content & learning
   */
  getContenentAndLearning =
    this.baseUrl2 + "/content-learning/get-course?program_id=1&course_id=";
  getTaskTypes = this.baseUrl2 + "/content-learning/course-type";
  courseCreation = this.baseUrl2 + "/content-learning/create-course";
  courseUserAssign = this.baseUrl2 + "/content-learning/map-course-users";
  updateContentAndLearning = this.baseUrl2 + "/content-learning/update-course";
  deleteContentAndLearning = this.baseUrl2 + "/content-learning/delete-course";
  createCourseQuiz = this.baseUrl2 + "/content-learning/create-quiz";
  updateCourseQuiz = this.baseUrl2 + "/content-learning/update-quiz";
  deleteCourseQuiz = this.baseUrl2 + "/content-learning/delete-quiz";
  getAllCourseQuiz =
    this.baseUrl2 + "/content-learning/get-quiz?program_id=1&course_id=";
  getContentAndLearningMappedUser =
    this.baseUrl2 + "/content-learning/mapped-course-user-lists";

  /**
   * Visit tracker
   */
  getVisits = this.baseUrl2 + "/visit-tracker/get-visits?program_id=1";
  createVisits = this.baseUrl2 + "/visit-tracker/create-visits";
  getVisitEnrollment =
    this.baseUrl2 + "/visit-tracker/get-enrollments?program_id=1";
  approveRejectEnroll =
    this.baseUrl2 + "/visit-tracker/approve-reject-enrollment";
  saveScheme = this.baseUrl2 + "/scheme/create-scheme-data";
  createEnroll = this.baseUrl2 + "/visit-tracker/create-enrollment";
  getOfferById = this.baseUrl2 + "/ecommerce/edit-offers?program_id=1&";

  /****/
  getTargetAchivementList = this.baseUrl2 + "/loyalty-target/get-targets";
  updateTargetAchivement = this.baseUrl2 + "/loyalty-target/update-target";
  addTargetAchivement = this.baseUrl2 + "/loyalty-target/create-target";
  assignedLoyaltyTarget = this.baseUrl2 + "/loyalty-target/get-loyalty-targets";
  createOffer = this.baseUrl2 + "/ecommerce/create-offers";
  deleteOffer = this.baseUrl2 + "/ecommerce/delete-offers";
  updateOffer = this.baseUrl2 + "/ecommerce/update-offers";
  createWhitelist = this.baseUrl2 + "/user-modules/create-user-module";
  getModule = this.baseUrl2 + "/module/get-modules";
  getUserModules = this.baseUrl2 + "/user-modules/get-user-modules";
  createLoyalty = this.baseUrl2 + "/loyalty-target/add-target-point-structure";
  getAssignedUser = this.baseUrl2 + "/loyalty-target/get-assigned-users";
  getLoyaltyTargets = this.baseUrl2 + "/loyalty-target/get-loyalty-targets";
  getPointStructure = this.baseUrl2 + "/points-structure/get-points-structure";
  updateLoyaltyTarget = this.baseUrl2 + "/loyalty-target/update-loyalty-target";
  updatePointsTarget =
    this.baseUrl2 + "/points-structure/update-points-structure";
  /**Excel section */
  uploadMultiProducts = this.baseUrl2 + "/product/upload-products";
  uploadMultiRewards = this.baseUrl2 + "/rewards/create-reward-products-excel";
  createSalesAchivement = this.baseUrl2 + "/loyalty-target/create-achievements";
  getSalesAchivements =
    this.baseUrl2 + "/loyalty-target/get-loyalty-achievements";
  updateSalesAchievement =
    this.baseUrl2 + "/loyalty-target/update-loyalty-achievement";
  approveSalesAchievement =
    this.baseUrl2 + "/loyalty-target/approve-sales-achievements";
  filterByCity = this.baseUrl2 + '/city/filter-city';
  productUploadExcelFields = this.baseUrl2 + '/product/product-upload-fields';
  getReardProductsWithPagination = this.baseUrl2 + '/rewards/get-reward-products-filter?program_id=1';
  getAllLoyaltyTargetsMapping = this.baseUrl2 + '/loyalty-target/get-loyalty-target-general-mapping?target_id=';
  getAllParentRolls = this.baseUrl2 + '/user-role/parentroles';
  getAllModules = this.baseUrl2 + '/user-modules/get-modules-lists';
  getRollProfileList = this.baseUrl2+'/user-role/profiles';
  getRollTypes = this.baseUrl2+'/user-role/roletypes';
  getTargetListBasedOnPage = this.baseUrl2 + "/loyalty-target/get-loyalty-target-general-mapping?target_id=";
  getPointListBasedOnPage = this.baseUrl2 + "/loyalty-target/get-loyalty-point-general-mapping?target_id=";
  createBrandTargets_general = this.baseUrl2 + "/loyalty-target/create-loyalty-general-setting";
  getHierarchyLevel_roles = this.baseUrl2+'/user-role/role-hierarchy';
  excel_upload_targetsDetails = this.baseUrl2 + '/loyalty-target/update-loyalty-targets';
  update_targets_for_sku = this.baseUrl2 + '/loyalty-target/update-loyalty-general-setting-with-products?id=';
  setTarget_points_for_sku = this.baseUrl2 + '/loyalty-target/create-loyalty-points-setting';
  createArchivements_excelUpload = this.baseUrl2 + "/loyalty-target/create-achievements";
  getArchivement_list = this.baseUrl2 + '/loyalty-target/get-achievements-list?program_id=1';
  generateCheckerAndMaker = this.baseUrl2 + "/loyalty-target/create-achievement-report";
  getGeneratedCheckerAndMaker = this.baseUrl2 + "/loyalty-target/get-achievement-report?program_id=1";
  getDownloadReport = this.baseUrl2 + "/loyalty-target/get-excel-achievement?program_id=1&";
  approveReport = this.baseUrl2 + "/loyalty-target/approve-reject-achievement";
  modifyCheckerAndMakerPoints = this.baseUrl2 + "/loyalty-target/maker-checker-transactions";
  brandsExcelHeaders = this.baseUrl2 + '/brands/brand-upload-fields';
  brandsExcelUpload = this.baseUrl2 + "/brands/upload-brands";
  deleteTarget = this.baseUrl2 + "/loyalty-target/delete-targets";
  getTargetPointBrands = this.baseUrl2+ "/brands/get-brand?program_id=1";
  getUserTargetPoints = this.baseUrl2 + "/loyalty-target/user-targets-points?target_id=";
  getUserTargets = this.baseUrl2 + "/loyalty-target/get-users-targets?target_id=";
  getUserPoints = this.baseUrl2 + "/loyalty-target/get-users-points?target_id=";
  getEngementDetailsForEdit = this.baseUrl2 + "/engagement/get-engagementbyid?eng_id=";
  getBrandCategoryHeaders = this.baseUrl2 + '/brand-category/excel-brand-category-headers';
  uploadExcelDataBrandCategory = this.baseUrl2+"/brand-category/upload-brand-category";
  engagementUserAssignExcel = this.baseUrl2+"/engagement/assign-user-task-excel";
  contentAndLearningUserAssignExcel = this.baseUrl2+"/content-learning/assign-user-task-excel";

  // All invoice api here ..
  getAllInvoice = this.baseUrl2+"/invoice/get-all-invoices";
  getApproveInvoice = this.baseUrl2+"/invoice/get-approved-invoices";
  getRejectInvoice = this.baseUrl2+"/invoice/get-rejected-invoices";
  getPendingInvoice = this.baseUrl2+"/invoice/get-pending-invoices";
  getProgramListInvoice = this.baseUrl2+"/invoice/get-program-list";
  updateInvoiceUrl = this.baseUrl2+"/invoice/update-invoice";
  approveInvoiceUrl = this.baseUrl2+"/invoice/approve-invoice";
  rejectInvoiceUrl = this.baseUrl2+"/invoice/reject-invoice";
  delProdInvoiceUrl = this.baseUrl2+"/invoice/delete-invoice-product";
  srcInvoiceUrl = this.baseUrl2+"/invoice/search-invoices";
  getOrderInvoiceUrl = this.baseUrl2+"/invoice/get-invoice-orders";

}
export interface ApiConfigurationInterface {}
