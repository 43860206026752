import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable as __Observable } from 'rxjs';

const TOKEN_KEY = 'auth-token';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationCheckService {

  /**
   * @author kailash_dev
   * @param AuthGuardService
   * Check the user log status.
   * < Write less & code more />
   */
  authenticationState:boolean = false;

  constructor() {
    this.checkToken();
  }

  async checkToken() {

    let token = await localStorage.getItem(TOKEN_KEY);
    if(token){
      this.authenticationState = true;
    }
    // console.log('checkToken',this.authenticationState)
  }
  getAuthToken(){
    // console.log('saveToken',this.authenticationState)
    return localStorage.getItem(TOKEN_KEY);
  }

  async saveAuth(token) {

    await localStorage.setItem(TOKEN_KEY,'Bearer '+token);
    this.authenticationState = true;
    // console.log('saveToken',this.authenticationState)
    return true;
  }

  logout() {

    localStorage.clear();
    this.authenticationState = false;
    // console.log('logout',this.authenticationState)
    return;
  }

   isAuthenticated() {
    let token =  localStorage.getItem(TOKEN_KEY);
    // console.log('token',token)
    if(token){
      this.authenticationState = true;
    }
    // console.log('authenticate',this.authenticationState)
    return this.authenticationState;
  }

}
