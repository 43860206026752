export const quizTypes: any = [
  {
    name: "Quiz",
    id: 1,
  },
  {
    name: "Image",
    id: 2,
  },
  // {
  //   name:'Event',
  //   id:3
  // }
];
export const subQuizTypes: any = [
  {
    name: "Text",
    id: 1,
  },
  {
    name: "Image",
    id: 2,
  },
];
export const colorCodes: any = [
  "#D9E3F0",
  "#F47373",
  "#697689",
  "#37D67A",
  "#2CCCE4",
  "#555555",
  "#dce775",
  "#ff8a65",
  "#ba68c8",
];

export const taskIntroTemplate: any = {
  template: `
    <div class="row">
    <div class="col-12" text-center>
        <p class="P-titlepic">Answer 6 simple Bud4Trade questions to win</p>

        <div class="main_i">
            <div class="coin_image"><img class="c_image" src="https://api1.bud4trade.bigcityexperiences.com/uploads/engagement_images/coin.png" style="" /></div>

            <div class="coin_i">
                <p class="P-titlePoints">13 COINS</p>
            </div>
        </div>
    </div>
  </div>

  <div class="row border" no-padding>
    <div class="col-6" col-6 no-padding>
        <div class="crads">
            <div class="item item-avatar"><img src="https://api1.bud4trade.bigcityexperiences.com/uploads/engagement_images/quiz1.png" /></div>
        </div>
    </div>

    <div class="col-6" col-6 no-padding>
        <div class="crads">
            <div class="item item-avatar"><img src="https://api1.bud4trade.bigcityexperiences.com/uploads/engagement_images/quiz2.png" /></div>
        </div>
    </div>
  </div>

  <div class="row margin_i" padding>
    <div class="row_inst" col-12 no-padding text-center>INSTRUCTIONS</div>
  </div>

  <div class="row margin_l">
    <ol>
        <li class="inst">Click on Start Quiz.</li>

        <li class="inst">Select the right answers for all questions.</li>

        <li class="inst">Earn assured points only if all questions are answered correctly.</li>
    </ol>
  </div>
  `,
};

export const campaignDetails: any = {
  campaign_id: "",
  engagement_type: "",
  title: "",
  subtitle: "",
  // activity_id:'',
  instruction: "",
  start_date: "",
  end_date: "",
  frequency: "",
  // user_role_id:'',
  help: "no data",
};
export const searchUsers: any = {
  filter_search_geography: [],
  filter_search_channel: [],
  filter_search_region: [],
  filter_search_userrole: [],
};
export const updateEngagementDetails: any = {
  campaign_id: 0,
  task_type: 0,
  subtask_type: 0,
  success_points: 0,
  user_role_id: 0,
  task_question_id: 0,
  subtask_question_id: null,
  program_id: 1,
  task_frequency: 0,
  is_information: 0,
  campaign_activities_config_id: 0,
  quiz_points_criteria: 0,
  starttimeslot: null,
  endtimeslot: null,
  created_date: null,
  updated_date: null,
  has_subtask: false,
  description: null,
  instruction: "",
  title: "",
  subtitle: "",
  help: "",
  success_msg: null,
  failure_msg: null,
};
export const createTextQuiz: any = {
  campaign_id: 0,
  campaign_activities_config_id: 0,
  question: "",
  question_type: 2,
  quiz_type: 0,
  option1: "",
  option2: "",
  option3: "",
  option4: "",
  option5: null,
  no_of_points: 0,
  time_in_seconds: null,
  display_date: null,
  display_time: null,
  created_date: "2020-04-12 10:30:46",
  updated_date: "2020-04-12 10:30:46",
  answer: "option3",
  is_subtask: null,
  program_id: 1,
  is_answer_check: false,
};
export const engagementRewardPoints: any = {
  rank: "Winner",
  points: "",
  isAdd: false,
  isRemove: false,
};
export const weelOfFortune: any = {
  ispointsOrReward: 2,
  products: [
    {
      id: 1,
      name: "product A",
    },
  ],
  divisions: [
    {
      id: 1,
      name: "Wheighttage Percentage",
    },
  ],
  dinomination: "",
  probability: "",
  qty: "",
  points: "",
  isAdd: false,
};
export const weelOfFortuneConfig: any = {
  button_text: "",
  frequency: "",
  max_winners_day: "",
  max_winners_engagement: "",
};
export const creatActivity: any = {
  campaign_id: 1,
  campaign_name: "",
  activity_title: "",
  trigger_name: "",
  activity_type: null,
  points: null,
  frequency_id: 1,
  frequency_name: "",
  activity_per_day: null,
  days: null,
  set_trigger: 0,
  program_id: 1,
  status: 1,
  start_date: null,
  end_date: null,
  created_date: null,
  updated_date: null,
  activity_desc: null,
  reward: null,
  success_msg: "",
  success_notify: "",
  success_sms: " ",
  fail_msg: "",
  fail_notify: null,
  fail_sms: "",
  instruction: "",
  task_id: null,
  id: null,
};

export const pointsDetails: any = {
  task_id: null,
  reward_type: 1,
  campaign_id: null,
  task_rank_based_reward: [],
  assured_type: 1,
  task_assured_fixed_point: { total_point: null },
  wof_title: "Wof Title",
  wof_configurations: [],
  max_winners: null,
  spin_btn_text: "Spin the wheel",
  frequency: 1,
  wof_configSettings: {
    denomination_text: "",
    weightage_percentage: null,
    wof_reward_type: 2,
    reward: null,
    color: "",
  },
};
export class TargetAchievementModel {
  denomination_text: string;
  weightage_percentage: number;
  wof_reward_type: number;
  reward: number;
  color: string;
  isAdd: boolean;
}
/**
 * Content&Learning
 */
export const createCourse: any = {
  program_id: 1,
  course_description: "",
  course_ana_title: null,
  video_url: null,
  course_type: 0,
  points: null,
  frequency_id: "",
  start_date: "",
  expiry_date: "",
  pre_expiry_date: "",
  quiz_configured: "",
  quiz_points_criteria: "",
  realted_to_engagement: "",
  module_enabled: null,
  module_expired: null,
  course_title: "",
  course_image: null,
  course_content: null,
  instruction_en: null,
};

export const TARGET_EXCEL: any[] = [
  "SKU",
  "Outlet Code",
  "Sales Date",
  "Qty"
  // "Outlet Name",
  // "TSE",
  // "ASM",
  // "Unit",
  // "TMM",
  // "Town",
  // "Name of CSM",
  // "Mobile No.",
  // "D.O.B.",
  // "Delivery address",
  // "Preferred Language",
];

export const WHITELISTING_EXCEL: any[] = ["Outlet Code"];

export const POINT_STRUCTURE_EXCEL: any[] = ["Outlet Code", "Product", "Points"];

export const USER_EXCEL: any[] = [
  "User Phone Number",
  "User Role",
  "Region",
  "Reporting User Number",
  "Geography",
  "Channel Type",
  "Channel",
  "State",
  "Outlet Code",
  "Outlet Name",
  "Outlet Email",
  "Name",
  "Licence No",
  "Address",
  "City",
  "Pincode",
  "Alternate Contact Number",
  "Employee Code",
  "Distributors",
];

export const sampleInstructionTemplateDetails: any = {
  title: "Answer 6 simple questions to win",
  points: "13",
  coin:
    "https://api1.bud4trade.bigcityexperiences.com/uploads/engagement_images/coin.png",
  wrong_banner: "",
  right_banner: "",
  intruction: [
    { text: "Click on Start Quiz." },
    { text: "Select the right answers for all questions." },
    {
      text: "Earn assured points only if all questions are answered correctly.",
    },
  ],
};
export const sampleInstructionTemplatetemplate:any = `
    <div class="row">    <div class="col-12" text-center>        <p class="P-titlepic">Answer 6 simple HubAdmin questions to win</p>        <div class="main_i">            <div class="coin_image"><img class="c_image" src="https://api1.bud4trade.bigcityexperiences.com/uploads/engagement_images/coin.png" style="" /> <p class="P-titlePoints">13 COINS</p></div>            <div class="coin_i">             </div>        </div>    </div>  </div>  <div class="row border" no-padding>    <div class="col-6" col-6 no-padding>        <div class="crads">            <div class="item item-avatar"><img src="https://api1.staging6.bigcityvoucher.co.in/uploads/content_learning/quiz_inst.png" style="max-width:400px;" /></div>        </div>    </div>    <div class="col-6" col-6 no-padding>        <div class="crads">            <div class="item item-avatar"></div>        </div>    </div>  </div>  <div class="row margin_i" padding>    <div class="row_inst" col-12 no-padding text-center>INSTRUCTIONS</div>  </div>  <div class="row margin_l">    <ol>        <li class="inst">Click on Start Quiz.</li>        <li class="inst">Select the right answers for all questions.</li>        <li class="inst">Earn assured points only if all questions are answered correctly.</li>    </ol>  </div>
`;

export const createQuizContentAndLearning: any = {
  question: "",
  course_id: 2,
  quiz_type: 0,
  options: [
    {
      option: "fgdf",
      is_right_option: 0,
      id: -1,
    },
    {
      option: "fgdf",
      is_right_option: 0,
      id: -1,
    },
  ],
  points: 0,
  program_id: 1,
};

export const courseContent: any = [
  {
    type: "img",
    data: "",
  },
  {
    type: "text",
    data: "",
  },
];
export const brandExcelHeaders: any = [
  "product_name",
  "sku_name",
  "product_image",
  "price",
  "points",
  "treshold_value",
  "product_description",
  "category_name",
  "category_image",
  "category_description",
  "sub_category",
  "sub_category_image",
  "is_loyalty",
  "variant",
  "variant_value",
  "stock",
  "distributaors",
  "unit",
  "discount_percentage",
  "offer_price",
  "min_buy_quantity",
  "max_buy_quantity",
  "min_offer_quantity",
  "max_offer_quantity ",
];
export const brandUploadExcelHeaders: any = [
  'category description',
"category name",
"is loyalty",
'price',
'product description',
'product name',
'sku name',
'threshold value',
'unit',
];

export const STATE_EXCEL_HEADER: any[] = ["States"];

export const GEOGRAPHY_EXCEL_HEADER: any[] = ["Geography"];

export const REGION_EXCEL_HEADER: any[] = ["Regions"];

export const CHANNEL_EXCEL_HEADER: any[] = ["Channels"];

export const CITY_EXCEL_HEADER: any[] = ["Cities"];

export const STATE_UPLOAD_HEADER : any[] = ["State Name", "Region Name", "State Code"];

export const REGION_UPLOAD_HEADER : any[] = ["Region Name"];

export const CITY_UPLOAD_HEADER : any[] = ["City Name", "State Name"];

export const LOYALTY_PRODUCT_HEADER : any[] = ["Product Name", "SKU","Product Short Name", "Unit"];
export const Roles_modules:any = [
  {
    name:'TSE',
    id:1,
    isChecked:false
  },
  {
    name:'Engagement',
    id:2,
    isChecked:false
  },
  {
    name:'Outlet',
    id:3,
    isChecked:false
  },
  {
    name:'Visit Tracker',
    id:4,
    isChecked:false
  },{
    name:'C&L',
    id:5,
    isChecked:false
  },
];

export const add_targets_for_brands:any = {
  program_id: 1,
  user_role_id: [],
  channel_id: [],
  region_id: [],
  state_id: [],
  city_id: [],
  target_id: null,
  is_generic:0,
  generic_target:0,
  loyalty_target_product_wise: []
}

export const role_hierarchy_mock:any = {
  label: '',
  type: 'person',
  styleClass: 'p-person',
  expanded: true,
  data: {name:'Walter White', 'avatar': 'assets/static/images/user.png'},
  children: []
}

export const month_list:any = [
	{
		"abbreviation": "Jan",
		"name": "January",
    id:1
	},
	{
		"abbreviation": "Feb",
		"name": "February",
    id:2
	},
	{
		"abbreviation": "Mar",
		"name": "March",
    id:3
	},
	{
		"abbreviation": "Apr",
		"name": "April",
    id:4
	},
	{
		"abbreviation": "May",
		"name": "May",
    id:5
	},
	{
		"abbreviation": "Jun",
		"name": "June",
    id:6
	},
	{
		"abbreviation": "Jul",
		"name": "July",
    id:7
	},
	{
		"abbreviation": "Aug",
		"name": "August",
    id:8
	},
	{
		"abbreviation": "Sep",
		"name": "September",
    id:9
	},
	{
		"abbreviation": "Oct",
		"name": "October",
    id:10
	},
	{
		"abbreviation": "Nov",
		"name": "November",
    id:11
	},
	{
		"abbreviation": "Dec",
		"name": "December",
    id:12
	}
]
export const regularExpression:any = {
  email:"[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}",
  alphanumeric:'/^[0-9a-zA-Z]+$/',
  noSpecialCharector:'^[0-9a-zA-Z \b]+$',
  graterThanZero:'^[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*$',
  latAndLang:'/^[0-9]*\.?[0-9]*$/',
  onlyNumAndSpecialChr:'([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))',
  dateValidation:'^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))'
}

export const role_hierarchy:any = [{
      level: 0,
      "parent_role_id": 0,
      name: "Organization",
      profile: "General",
      expanded: true,
      "children": [
              {
              "level": 1,
              "parent_role_id": 33,
              "name": "general manager",
              "profile": "Sales",
              expanded: true,
              "children": [
                  {
                      "level": 2,
                      "parent_role_id": 30,
                      "name": "AGM",
                      "profile": "Sales",
                      expanded: true
                  },
                  {
                      "level": 2,
                      "parent_role_id": 30,
                      "name": "Asst General Manger",
                      "profile": "Sales",
                      expanded: true,
                  },
                  {
                      "level": 2,
                      "parent_role_id": 30,
                      "name": "FMCG retailor ",
                      "profile": "General",
                      expanded: true,
                  },
                  {
                      "level": 2,
                      "parent_role_id": 30,
                      "name": "manager test",
                      "profile": "Sales",
                      expanded: true,
                      "children": [
                          {
                              "level": 3,
                              "parent_role_id": 40,
                              "name": "manager test 2",
                              "profile": "Sales",
                              expanded: true,
                          }
                      ]
                  },
                  {
                      "level": 2,
                      "parent_role_id": 30,
                      "name": "general child",
                      "profile": "General",
                      expanded: true,
                  }
              ]
          },
          {
              "level": 1,
              "parent_role_id": 33,
              "name": "SSE",
              "profile": "Sales",
              expanded: true,
              "children": [
                  {
                      "level": 2,
                      "parent_role_id": 35,
                      "name": "TSE",
                      "profile": "Sales",
                      expanded: true,
                      "children": [
                          {
                              "level": 3,
                              "parent_role_id": 4,
                              "name": "sample dev test",
                              "profile": "Sales",
                              expanded: true,
                          },
                          {
                              "level": 3,
                              "parent_role_id": 4,
                              "name": "Outlet",
                              "profile": "Sales",
                              expanded: true,
                          }
                      ]
                  },
                  {
                      "level": 2,
                      "parent_role_id": 35,
                      "name": "SA-BLR",
                      "profile": "Sales",
                      expanded: true,
                  }
              ]
          },
          {
              "level": 1,
              "parent_role_id": 33,
              "name": "M3",
              "profile": "Sales",
              expanded: true,
              "children": [
                  {
                      "level": 2,
                      "parent_role_id": 38,
                      "name": "m2",
                      "profile": "Sales",
                      expanded: true,
                  }
              ]
          }
      ]
  }
]
export const role_hierarchy2:any = [{
  label: 'Organization Level',
  expanded: true,
  profile:'Walter White',
  children: [
      {
          label: 'CFO 2',
          expanded: true,
          profile:'Saul Goodman',
          children:[{
              label: 'Tax',
              styleClass: 'department-cfo'
          },
          {
              label: 'Legal',
              styleClass: 'department-cfo'
          }],
      },
      {
          label: 'COO',
          expanded: true,
          profile:'Mike E.',
          children:[{
              label: 'Operations',
              styleClass: 'department-coo'
          }]
      },
      {
          label: 'CTO',
          expanded: true,
          profile:'Jesse Pinkman',
          children:[{
              label: 'Development',
              expanded: true,
              children:[{
                  label: 'Analysis',
              },
              {
                  label: 'Front End',
              },
              {
                  label: 'Back End',
              }]
          },
          {
              label: 'QA',
          },
          {
              label: 'R&D',
          }]
      }
  ]
}];



