import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './hubadmin/layout/layout.component';
import { SigninComponent } from './common/signin/signin.component';
import { AuthGuardService as AuthGuard } from './services/auth/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full',
  },
  {
    path: 'signin',
    component: SigninComponent,
   
  },
  {
    path: 'hubAdmin',
    component: LayoutComponent,
    children:[
      {
        path: '',
        loadChildren: './hubadmin/layout/layout.module#LayoutModule'
      }
    ],
    canActivate: [AuthGuard]
  },
   { path: '**', redirectTo: 'signin' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    useHash: true
 })
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
