import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from 'src/app/services/file/file.service';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';
import { ToastClientService } from 'src/app/services/toast/toast-client.service';
import { UploadZipService } from 'src/app/services/upload-zip/upload-zip.service';

@Component({
  selector: 'app-upload-excel-details',
  templateUrl: './upload-excel-details.component.html',
  styleUrls: ['./upload-excel-details.component.scss']
})
export class UploadExcelDetailsComponent implements OnInit {
  /**
   * @author kailash_dev
   * @param FileUploadWithProgress
   * This this common module for all the components upload big file and while processing(uploading) user can go other componets.
   * For this module api loader disabled in interceptor by setting header skip=true.
   * < Write less & code more />
   */
  minimiseClass:string = '';
  isminimise:boolean = false;
  progress:number = 0;
  @Input() data: any;
  fileSize:string;
  uploadFileSubscribe;
  apiCalled:any = {status:false,text:'Uploading...'};
  uploadDetails:FileList;
  modalRef;
  uploadedStatus:any;
  constructor(private uploadFile:UploadZipService,private modalService: NgbModal,
    private config: ApiConfiguration,private toast: ToastClientService,
    private apiService: ApiService,public fileService:FileService,) { }
  ngOnInit() {
    console.log(this.data);
  }
  minimiseDialogue(){
    if(this.isminimise){
      this.minimiseClass = 'maximiseTheDIv'
    }else{
      this.minimiseClass = 'minimiseTheDIv'
    }
    this.isminimise = !this.isminimise;
    console.log("clling...");
  }
  uploadZipWithExcelFile(files: FileList){
      this.uploadDetails = files;
      this.fileSize = (files[0].size/1024/1024).toFixed(2)+ ' MB';
      console.log((files[0].size/1024/1024).toFixed(2)+ ' MB');
  }
  uploadToServer(files: FileList){
    this.apiCalled.status = true;
    this.uploadFileSubscribe = this.apiService.upload(this.data.url,this.uploadDetails,this.data.data).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        this.progress = this.fileService.calcProgressPercent(event);
        console.log(this.progress);
      } else if (event instanceof HttpResponse) {
        if(event.body.data){
          this.uploadedStatus = event.body.data;
          this.toast.Success("Record Inserted Successfully !!");
        }
        this.apiCalled.text = "Uploaded";
      }
    },err=>{
      console.log('err', err);
      this.apiCalled.text = "Failed!";
      this.toast.Error("Record Inserted Failed !!");
    });
  }
  public cancelUpload(){
    this.uploadFileSubscribe.unsubscribe();
    this.apiCalled.status = false;
    this.uploadDetails = null;
    this.fileSize = null;
    this.modalRef.dismiss();
    this.uploadFile.changeMessage({status:'close'});
  }
  closePanel(target){
    this.modalRef =this.modalService.open(target, {
      centered: true,
      size: 'sm',
      scrollable: true,
    });
  }
  closeUploadDialogue(target){
    if(this.fileSize&&this.apiCalled.status){
      if(this.apiCalled.text == "Uploaded"){
        this.uploadFile.changeMessage({status:'close'});
      }else{
        this.closePanel(target);
      }
    }else{
      this.uploadFile.changeMessage({status:'close'});
    }
  }
}
