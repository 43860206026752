import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  constructor( private route:Router) { }

  ngOnInit() {
  }

  toggleSidebar(){
    console.log('sd')
    let body = document.body;
    body.classList.toggle("is-collapsed");
  }

  goDashboard()
  {
    this.route.navigateByUrl("/hubAdmin/dashboard");
  }

}
