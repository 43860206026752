import { BrowserModule } from "@angular/platform-browser";
import { Injector, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LayoutComponent } from "./hubadmin/layout/layout.component";
import { SidebarComponent } from "./hubadmin/sidebar/sidebar.component";
import { NavbarComponent } from "./hubadmin/navbar/navbar.component";
import { SigninComponent } from "./common/signin/signin.component";
import {
  HttpClientModule,
  HttpXhrBackend,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { HttpConfigInterceptor } from "./services/interceptor/http-loading-interceptor";
import { XlsxViewComponent } from "./shared/xlsx-view/xlsx-view.component";
import { SharedModule } from "./shared/shared.module";
import { Router } from "@angular/router";
import { CacheInterceptor } from "./shared/Http/cache.interceptor";
import { ToastComponent } from "./shared/toast/toast.component";
import { ToastService } from "./services/toast/toast.service";
import { DatePipe } from "@angular/common";
import { ExportExcelService } from "./shared/xlsx-view/export-excel.service";
import { ColorPaletteComponent } from "./shared/color-palette/color-palette.component";
import { UploadExcelDetailsComponent } from "./shared/upload-excel-details/upload-excel-details.component";
import { UploadZipService } from "./services/upload-zip/upload-zip.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

//import { ChartsModule } from 'ng2-charts';
import { ChartsModule } from 'ng2-charts/ng2-charts';
 
@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    SidebarComponent,
    NavbarComponent,
    SigninComponent,
    ToastComponent,
    UploadExcelDetailsComponent,
    
  ],

    imports: [BrowserModule,BrowserAnimationsModule, AppRoutingModule, SharedModule.forRoot(),ChartsModule,
  ],
  providers: [
    ToastService,
    DatePipe,
    ExportExcelService,
    UploadZipService,
    { provide: HttpClientModule, deps: [HttpXhrBackend, Router, Injector] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
  ],
  entryComponents: [XlsxViewComponent, ColorPaletteComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
