import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }

  toggleSidebar(){
    console.log('sd')
    let body = document.body;
    body.classList.toggle("is-collapsed");
  }

  redirectWhitelist()
  {
    this.router.navigateByUrl('/hubAdmin/whitelisting')
  }
  logout()
  {
    console.log('localStorage')
    localStorage.clear()
  }

}
